import { combineReducers } from "redux";
import { tenantsReducer } from "../../features/tenant/tenant/Tenants.slice";
import { tenantOnboardingReducer } from "../../features/tenant/onboarding/Onboarding.slice";
import { toasterReducer } from "../../components/Toaster/Toaster.slice";
import { paymentsReducer } from "../../features/collections/payments/Payments.slice";
import { invoicesReducer } from "../../features/collections/invoices/Invoices.slice";
import { fileExplorerReducer } from "../../features/file_explorer/fileExplorer.slice";
import { appReducer } from "../../App.slice";
import { programsReducer } from "../../features/programs/Programs.slice";
import { usersReducer } from "../../features/users/pages/shared/api";
import { notesReducer } from '../../features/notes/Notes.slice'
export const rootReducer = combineReducers({
  tenants: tenantsReducer,
  tenantOnboarding: tenantOnboardingReducer,
  toaster: toasterReducer,
  payments: paymentsReducer,
  invoices: invoicesReducer,
  fileExplorer: fileExplorerReducer,
  app: appReducer,
  programs: programsReducer,
  users: usersReducer,
  notes: notesReducer,
})
