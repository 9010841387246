import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNotesApi, addNoteApi, updateNoteApi, deleteNoteApi } from '../../apis/apis'
import { Paginated, Note, NoteSearchParams } from '../../apis/types'
import type { RootState, AppDispatch } from '../../shared/redux/store'

const SLICE_NAME = 'NOTES_SLICE'

export const getNotes = createAsyncThunk<
  Paginated<Note>,
  NoteSearchParams,
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/getNotes`, async (query: NoteSearchParams, { rejectWithValue }) => {
  try {
    const response = await getNotesApi(query)
    return { ...response }
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

export const addNote = createAsyncThunk<
  Note,
  { sourceEntity: string; body: string },
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/addNote`, async ({ sourceEntity, body }: { sourceEntity: string; body: string }, { rejectWithValue }) => {
  try {
    const response = await addNoteApi({ sourceEntity, body })
    return { ...response, sourceEntity }
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

export const updateNote = createAsyncThunk<
  Note,
  { noteCuid: string; body?: string; isPinned?: boolean; tags?: string[] },
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(
  `${SLICE_NAME}/updateNote`,
  async ({ noteCuid, body, isPinned, tags }: { noteCuid: string; body?: string; isPinned?: boolean; tags?: string[] }, { rejectWithValue }) => {
    try {
      const response = await updateNoteApi({ noteCuid, body, isPinned, tags })
      return { ...response }
    } catch (err) {
      return rejectWithValue({
        err: err !== undefined,
      })
    }
  }
)

export const deleteNote = createAsyncThunk<
  Note,
  { noteCuid: string },
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/deleteNote`, async ({ noteCuid }: { noteCuid: string }, { rejectWithValue }) => {
  try {
    const response = await deleteNoteApi({ noteCuid })
    return { ...response }
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

interface state {
  loading: boolean
  error: boolean
  noteRequestPhase: { kind: 'idle' } | { kind: 'in-progress' } | { kind: 'failed' }
  sourceEntities: {
    [key: string]: Note[]
  }
  total: number
  resultsPerPage: number
  page: number
}

const initialState: state = {
  loading: false,
  error: false,
  noteRequestPhase: { kind: 'idle' },
  sourceEntities: {},
  total: 0,
  resultsPerPage: 0,
  page: 0,
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotes.pending, (state) => {
      state.noteRequestPhase = { kind: 'in-progress' }
    })
    builder.addCase(getNotes.fulfilled, (state, { payload }) => {
      state.noteRequestPhase = { kind: 'idle' }
      state.sourceEntities = {}
      payload.results?.forEach((note) => {
        if (!state.sourceEntities[note.sourceEntity]) {
          state.sourceEntities[note.sourceEntity] = []
        }
        state.sourceEntities[note.sourceEntity].push(note)
      })
      state.total = payload.total ?? 0
      state.resultsPerPage = payload.resultsPerPage ?? 0
      state.page = payload.page ?? 0
    })
    builder.addCase(getNotes.rejected, (state, { payload, error }) => {
      if (payload != null) {
        state.noteRequestPhase = { kind: 'failed' }
      } else {
        state.error = error !== undefined
      }
    })

    builder.addCase(addNote.pending, (state) => {
      state.noteRequestPhase = { kind: 'in-progress' }
    })
    builder.addCase(addNote.fulfilled, (state, { payload }) => {
      state.noteRequestPhase = { kind: 'idle' }
      state.sourceEntities[payload.sourceEntity].unshift(payload)
    })
    builder.addCase(addNote.rejected, (state, { payload, error }) => {
      if (payload != null) {
        state.noteRequestPhase = { kind: 'failed' }
      } else {
        state.error = error !== undefined
      }
    })

    builder.addCase(updateNote.pending, (state) => {
      state.noteRequestPhase = { kind: 'in-progress' }
    })
    builder.addCase(updateNote.fulfilled, (state, { payload }) => {
      state.noteRequestPhase = { kind: 'idle' }
      state.sourceEntities[payload.sourceEntity] = state.sourceEntities[payload.sourceEntity].map((note) =>
        note.cuid === payload.cuid ? { ...payload, sourceEntityName: note.sourceEntityName } : note
      )
    })
    builder.addCase(updateNote.rejected, (state, { payload, error }) => {
      if (payload != null) {
        state.noteRequestPhase = { kind: 'failed' }
      } else {
        state.error = error !== undefined
      }
    })

    builder.addCase(deleteNote.pending, (state) => {
      state.noteRequestPhase = { kind: 'in-progress' }
    })
    builder.addCase(deleteNote.fulfilled, (state, { payload }) => {
      state.noteRequestPhase = { kind: 'idle' }
      if (payload != null) {
        const index = state.sourceEntities[payload.sourceEntity].findIndex((note) => note.cuid === payload.cuid)
        state.sourceEntities[payload.sourceEntity].splice(index, 1)
      }
    })
    builder.addCase(deleteNote.rejected, (state, { payload, error }) => {
      if (payload != null) {
        state.noteRequestPhase = { kind: 'failed' }
      } else {
        state.error = error !== undefined
      }
    })
  },
})

export const notesReducer = slice.reducer
