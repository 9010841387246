import React, { useState, useRef, useMemo } from 'react'
import Button from '../../../components/Form/Button'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'
import { Note } from '../../../apis/types'
import NoteItem from './NoteItem'

export interface NotesPanelProps {
  noteInput: string
  notes: Note[]
  noteRequestPhase: { kind: 'idle' } | { kind: 'in-progress' } | { kind: 'failed' }
  setNoteInput: (value: string) => void
  onPost: (sourceEntity: string, body: string) => Promise<void>
  onUpdate: (noteCuid: string, body: string, tags: string[]) => Promise<void>
  onDelete: (note: Note) => Promise<void>
  onTogglePin: (note: Note) => Promise<void>
  currentUserId: string
  sourceEntity: string
}

const useSortedNotes = (notes: Note[], sortDirection: 'asc' | 'desc') => {
  return useMemo(
    () =>
      [...notes].sort((a, b) =>
        sortDirection === 'asc'
          ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      ),
    [notes, sortDirection]
  )
}

export const NotesPanel: React.FC<NotesPanelProps> = ({ notes = [], ...props }) => {
  const isPostingNote = props.noteRequestPhase.kind === 'in-progress'
  const isPostInputEmpty = props.noteInput.length === 0
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(() => {
    const stored = localStorage.getItem('noteSortDirection')
    return stored === 'asc' || stored === 'desc' ? stored : 'desc'
  })
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  // Add useEffect for auto-focus
  React.useEffect(() => {
    textareaRef.current?.focus()
  }, [])

  const sortedNotes = useSortedNotes(notes, sortDirection)

  const handleSort = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    setSortDirection(newDirection)
    localStorage.setItem('noteSortDirection', newDirection)
  }

  const handleTextAreaInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight + 2}px`
    props.setNoteInput(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (!isPostingNote && !isPostInputEmpty) {
        props.onPost(props.sourceEntity, props.noteInput)
        props.setNoteInput('')
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto'
        }
      }
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!isPostingNote && !isPostInputEmpty) {
      await props.onPost(props.sourceEntity, props.noteInput)
      props.setNoteInput('')
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto'
      }
    }
  }

  return (
    <div className="flex flex-col">
      <form onSubmit={handleSubmit} className="flex align-middle">
        <div className="flex-1">
          <textarea
            ref={textareaRef}
            value={props.noteInput}
            disabled={isPostingNote}
            rows={1}
            placeholder="Add a note..."
            className={[
              // Layout
              'block w-full',
              'px-2 py-1.5',
              'overflow-hidden resize-none',
              // Typography
              'sm:text-sm sm:leading-6',
              'text-gray-900 dark:text-gray-100',
              'placeholder:text-gray-400 dark:placeholder:text-gray-500',
              // Visual styling
              'rounded-md',
              'border',
              'shadow-sm',
              'ring-1',
              'ring-gray-300 dark:ring-gray-700',
              'border-gray-300 dark:border-gray-700',
              'focus:ring-indigo-600 dark:focus:ring-indigo-600',
              'dark:bg-slate-900',
              // Focus states
              'focus:border-indigo-600 focus:dark:border-indigo-600',
              'focus:ring-indigo-600 dark:focus:ring-indigo-600',
            ].join(' ')}
            onChange={handleTextAreaInput}
            onKeyDown={handleKeyDown}
            aria-label="Note input"
          />
        </div>
        <div className="ml-2">
          <Button type="submit" disabled={isPostingNote || isPostInputEmpty} className="h-full" aria-label="Post note">
            Post
          </Button>
        </div>
      </form>

      {notes?.length > 1 && (
        <div className="flex items-center mt-3">
          <Button
            size={1}
            color="slate"
            className="inline w-fit"
            onClick={handleSort}
            aria-label={`Sort notes ${sortDirection === 'asc' ? 'newest first' : 'oldest first'}`}
          >
            <div className="text-xs">
              <div className="flex items-center">
                {sortDirection === 'asc' ? <ArrowDownIcon className="w-4 h-4 mr-1 -ml-1" /> : <ArrowUpIcon className="w-4 h-4 mr-1 -ml-1" />}
                {sortDirection === 'asc' ? 'Oldest first' : 'Newest first'}
              </div>
            </div>
          </Button>
          <span className="text-xs ml-2" role="status">
            {notes.length} note{notes.length === 1 ? '' : 's'}
          </span>
        </div>
      )}

      <div className={classnames('mt-2 h-full overflow-y-auto overscroll-contain transition-all duration-200')} role="log" aria-label="Notes list">
        {sortedNotes?.filter((note) => note.isPinned).length > 0 && (
          <>
            <div className="flex flex-row justify-center w-full border-t border-slate-300 dark:border-slate-600 mt-2 -mb-2 text-bg-red-500">
              <span className="border-slate-200 bg-slate-50 dark:bg-slate-800 text-slate-500 dark:text-slate-300 relative -mt-2 text-xs px-2">Pinned</span>
            </div>
            {sortedNotes
              ?.filter((note) => note.isPinned)
              .map((note, index) => (
                <NoteItem
                  key={note.cuid}
                  note={note}
                  isLast={index === notes.length - 1}
                  onUpdate={props.onUpdate}
                  onDelete={props.onDelete}
                  onTogglePin={() => props.onTogglePin(note)}
                  canModify={props.currentUserId === note.author.email}
                />
              ))}
            <hr className="mt-4 border-slate-300 dark:border-slate-700" />
          </>
        )}
        {sortedNotes
          ?.filter((note) => !note.isPinned)
          .map((note, index) => (
            <NoteItem
              key={note.cuid}
              note={note}
              isLast={index === notes.length - 1}
              onUpdate={props.onUpdate}
              onDelete={props.onDelete}
              onTogglePin={() => props.onTogglePin(note)}
              canModify={props.currentUserId === note.author.email}
            />
          ))}
      </div>
    </div>
  )
}

export default NotesPanel
