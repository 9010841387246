import React from 'react'
import { useField } from 'formik'
import { Switch } from '@headlessui/react'
import classnames from 'classnames'

interface FormikToggleProps {
  name: string
  label: string
  disabled?: boolean
}

const FormikToggle: React.FC<FormikToggleProps> = ({ name, label, disabled }) => {
  const [field, , helpers] = useField(name)

  return (
    <Switch.Group as="div" className="flex">
      <Switch.Label as="span" className="mr-3 pointer-events-none">
        <span className="text-sm font-medium text-slate-700 dark:text-slate-300">{label}</span>
      </Switch.Label>

      <Switch
        checked={field.value}
        onChange={(newCheckedValue: boolean) => helpers.setValue(newCheckedValue)}
        className={classnames(
          field.value === true ? 'bg-emerald-500 dark:bg-emerald-600' : 'bg-slate-200 dark:bg-slate-700',
          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-slate-900'
        )}
        disabled={disabled}
      >
        <span
          aria-hidden="true"
          className={classnames(
            field.value === true ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-slate-100 shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

export default FormikToggle
