import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTenantsApi,
  getTenantApi,
  getPropertyListApi,
  getInvoiceListApi,
  getUtilityListApi,
  getBillingOptionsApi,
  getIncentivePlanOptionsApi,
} from '../../../apis/apis'

import {
  type Tenant,
  type TenantItem,
  type ServiceAccount,
  type Paginated,
  type GetTenantsArgs,
  type GetTenantsData,
  type GetTenantArgs,
  type GetInvoicesListData,
  type CollectionsInvoice,
  type Utility,
  BillingOptionsResult,
  BillingOptionObject,
  IncentivePlanOptionsResult,
  IncentivePlanOptionObject,
} from '../../../apis/types'
import { formatTitleCase } from '../../../util/string'
import type { RootState, AppDispatch } from '../../../shared/redux/store'

const SLICE_NAME = 'TENANTS_SLICE'

export const getTenants = createAsyncThunk<
  GetTenantsData,
  GetTenantsArgs,
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/getTenants`, async (query, { rejectWithValue }) => {
  try {
    const response = await getTenantsApi(query)
    return response
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

export interface StoredTenant extends Tenant {
  serviceAccounts: Array<ServiceAccount & { invoices: GetInvoicesListData }>
}

export const getTenant = createAsyncThunk<
  StoredTenant,
  GetTenantArgs,
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/getTenant`, async (tenantId, { rejectWithValue }) => {
  try {
    const { serviceAccounts, ...rest } = await getTenantApi(tenantId)
    const results = await Promise.all(serviceAccounts.map(async (sa) => await getInvoiceListApi({ tenantAccountId: sa.id, resultsPerPage: 5 })))
    const accounts: Array<ServiceAccount & { invoices: GetInvoicesListData }> = serviceAccounts.map((account, index) => ({
      ...account,
      invoices: results[index],
    }))

    return { ...rest, serviceAccounts: accounts }
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

export const getPropertyList = createAsyncThunk<
  Array<{
    id: string
    nameLocation: string
    name: string
    location: string
  }>,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/getPropertyList`, async (_, { rejectWithValue }) => {
  try {
    const properties = await getPropertyListApi()
    return properties.map((p) => {
      const [name, location] = p.name.split(' - ')
      return {
        id: p.id,
        nameLocation: p.name,
        name,
        location,
      }
    })
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

export const getUtilityList = createAsyncThunk<
  Utility[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/getUtilityList`, async (_, { rejectWithValue }) => {
  try {
    const utilities = await getUtilityListApi()
    return utilities
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

export const getInvoicesByTenantId = createAsyncThunk<
  GetInvoicesListData,
  string,
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/getInvoicesByTenantId`, async (tenantId, { rejectWithValue }) => {
  try {
    const response = await getInvoiceListApi({ tenantId, resultsPerPage: 5 })
    return response
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

export const getBillingOptions = createAsyncThunk<
  BillingOptionsResult,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/getBillingOptions`, async (_, { rejectWithValue }) => {
  try {
    const res = await getBillingOptionsApi()
    const toObject = (str: string): BillingOptionObject => ({
      [str]: formatTitleCase(str),
    })

    const titleCaseRes: BillingOptionsResult = {
      utilityBillTypes: res.utilityBillTypes.map(toObject),
      kingBillTypes: res.kingBillTypes.map(toObject),
      enrollmentStatuses: res.enrollmentStatuses.map(toObject),
      billingMode: res.billingMode.map(toObject),
    }

    return titleCaseRes
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

export const getIncentivePlanOptions = createAsyncThunk<
  IncentivePlanOptionsResult,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    state: RootState
    dispatch: AppDispatch
    rejectValue: { err: boolean }
  }
>(`${SLICE_NAME}/getIncentivePlanOptions`, async (_, { rejectWithValue }) => {
  try {
    const res = await getIncentivePlanOptionsApi()
    const toObject = (str: string): IncentivePlanOptionObject => ({
      [str]: formatTitleCase(str),
    })

    const titleCaseRes: IncentivePlanOptionsResult = {
      incentivePlanTypes: res.incentivePlanTypes.map(toObject),
      incentivePlanStatuses: res.incentivePlanStatuses.map(toObject),
      incentivePlanCadences: res.incentivePlanCadences.map(toObject),
      incentivePlanInstallmentStatuses: res.incentivePlanInstallmentStatuses.map(toObject),
    }

    return titleCaseRes
  } catch (err) {
    return rejectWithValue({
      err: err !== undefined,
    })
  }
})

interface state {
  loading: boolean
  error: boolean
  refreshingTenant: boolean
  tenants?: Paginated<TenantItem>
  tenant?: StoredTenant
  properties: Array<{
    id: string
    nameLocation: string
    name: string
    location: string
  }>
  utilities: Utility[]
  invoices: {
    loading: boolean
    error: boolean
    results?: Paginated<CollectionsInvoice>
  }
  billingOptions?: BillingOptionsResult
  incentivePlanOptions?: IncentivePlanOptionsResult
}

const initialState: state = {
  loading: false,
  error: false,
  refreshingTenant: false,
  tenants: undefined,
  tenant: undefined,
  properties: [],
  utilities: [],
  invoices: {
    loading: false,
    error: false,
    results: undefined,
  },
  billingOptions: undefined,
  incentivePlanOptions: undefined,
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTenants.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getTenants.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = false
      state.tenants = payload
    })
    builder.addCase(getTenants.rejected, (state, { payload, error }) => {
      state.loading = false

      if (payload != null) {
        state.error = payload.err
      } else {
        state.error = error !== undefined
      }
    })

    builder.addCase(getTenant.pending, (state: state, { meta }) => {
      state.loading = state.tenant === undefined
      state.refreshingTenant = state.tenant?.id === meta.arg
      state.error = false
    })
    builder.addCase(getTenant.fulfilled, (state, { payload }) => {
      state.loading = false
      state.refreshingTenant = false
      state.error = false
      state.tenant = payload
    })
    builder.addCase(getTenant.rejected, (state, { payload, error }) => {
      state.loading = false
      state.refreshingTenant = false

      if (payload != null) {
        state.error = payload.err
      } else {
        state.error = error !== undefined
      }
    })

    builder.addCase(getPropertyList.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getPropertyList.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = false
      state.properties = payload
    })
    builder.addCase(getPropertyList.rejected, (state: state, { payload, error }) => {
      state.loading = false

      if (payload != null) {
        state.error = payload.err
      } else {
        state.error = error !== undefined
      }
    })

    builder.addCase(getUtilityList.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getUtilityList.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = false
      state.utilities = payload
    })
    builder.addCase(getUtilityList.rejected, (state: state, { payload, error }) => {
      state.loading = false

      if (payload != null) {
        state.error = payload.err
      } else {
        state.error = error !== undefined
      }
    })

    builder.addCase(getInvoicesByTenantId.pending, (state) => {
      state.invoices.loading = true
      state.invoices.error = false
    })
    builder.addCase(getInvoicesByTenantId.fulfilled, (state, { payload }) => {
      state.invoices.loading = false
      state.invoices.error = false
      state.invoices.results = payload
    })
    builder.addCase(getInvoicesByTenantId.rejected, (state, { payload, error }) => {
      state.invoices.loading = false

      if (payload != null) {
        state.error = payload.err
      } else {
        state.error = error !== undefined
      }
    })

    builder.addCase(getBillingOptions.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getBillingOptions.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = false
      state.billingOptions = payload
    })
    builder.addCase(getBillingOptions.rejected, (state, { payload, error }) => {
      state.loading = false

      if (payload != null) {
        state.error = payload.err
      } else {
        state.error = error !== undefined
      }
    })
    builder.addCase(getIncentivePlanOptions.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getIncentivePlanOptions.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = false
      state.incentivePlanOptions = payload
    })
    builder.addCase(getIncentivePlanOptions.rejected, (state, { payload, error }) => {
      state.loading = false

      if (payload != null) {
        state.error = payload.err
      } else {
        state.error = error !== undefined
      }
    })
  },
})

export const tenantsReducer = slice.reducer;
