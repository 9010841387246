import classnames from 'classnames'

export const getInputStyles = (input?: { disabled?: boolean; error?: boolean }): string => {
  return classnames(
    // Base styles
    [
      // Layout & Sizing
      'block w-full',
      'px-2 py-1.5',
      'sm:text-sm sm:leading-6',

      // Colors & Theme
      'text-gray-900 dark:text-gray-100',
      'bg-white dark:bg-slate-900',
      'border border-gray-300 dark:border-gray-700',
      'placeholder:text-gray-400 dark:placeholder:text-gray-500',

      // Visual Effects
      'rounded-md',
      'shadow-sm',
      'ring-gray-300 dark:ring-gray-700',
      'ring-1 focus:ring-1',
    ].join(' '),
    // Disabled state
    input?.disabled === true ? 'opacity-60 bg-gray-50 dark:bg-slate-700 cursor-not-allowed' : 'bg-white dark:bg-slate-900',
    // Error state
    {
      'ring-red-500 dark:ring-red-500 focus:ring-red-500 dark:focus:ring-red-500': input?.error,
      'border-red-500 dark:border-red-500 focus:border-red-500 dark:focus:border-red-500': input?.error,
      // Default focus state (only applied when not in error state)
      'focus:ring-indigo-600 focus:border-indigo-600 dark:focus:border-indigo-600 dark:focus:ring-indigo-600': !input?.error,
    }
  )
}
