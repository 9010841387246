import React from "react";
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'
import { getInputStyles } from './styles'

export interface InputProps extends React.ComponentProps<'input'> {
  label?: string
  value: string
  error?: string
  className?: string
  displayField?: boolean
  required?: boolean
  disabled?: boolean
  description?: string
  placeholder?: string
  type?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Input: React.FC<InputProps> = (props) => {
  const { label, value, error, className, displayField, required, disabled, description, type = 'text', onChange, onBlur, placeholder, ...rest } = props

  return (
    <div className={className}>
      {label !== undefined && (
        <label
          htmlFor={label}
          className={classnames('block text-sm font-medium text-slate-700 dark:text-slate-300 truncate text-left', description != null ? 'h-9' : 'h-5')}
        >
          <div className="flex flex-col">
            <div>
              {label}
              {required === true && <span className="ml-1 text-sm text-red-500 dark:text-red-500">*</span>}
            </div>
            {description != null && <span className="text-xs text-slate-500 dark:text-slate-400">{description}</span>}
          </div>
        </label>
      )}
      <div className="relative z-0 rounded-md">
        {displayField ?? false ? (
          <span className="h-10 block w-full text-sm text-slate-700 dark:text-slate-100 truncate">{value}</span>
        ) : (
          <input
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            className={classnames(getInputStyles({ disabled, error: error != null }))}
            value={value}
            {...rest}
            autoComplete="off"
            disabled={disabled}
            placeholder={placeholder}
          />
        )}
        {error !== undefined && (
          <div className="absolute z-0 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationTriangleIcon className="h-5 w-5 text-red-500 dark:text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error !== undefined && (
        <p className="mt-1 text-xs text-left text-red-600 dark:text-red-500" id="email-error">
          {error}
        </p>
      )}
    </div>
  )
}

export default Input;
