import React from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import { NavItem } from './navConfig'

interface NavItemComponentProps {
  item: NavItem
  isBaseRouteActive: boolean
}

export const NavItemComponent: React.FC<NavItemComponentProps> = ({ item }) => {
  return (
    <NavLink to={item.to}>
      {({ isActive }) => (
        <div
          className={classnames(
            // Base styles
            'no-underline font-normal whitespace-nowrap px-4 py-2.5',
            !isActive && 'text-slate-600 dark:text-slate-400',
            // Border and transition
            'border-l-4 border-transparent transition-all duration-200 ease-in-out',
            // SVG icon styles
            '[&>svg]:inline-block [&>svg]:mr-2.5 [&>svg]:mb-0.5',
            // Hover states
            //   !isActive && 'hover:bg-slate-50 dark:hover:bg-slate-800',
            !isActive && 'hover:text-indigo-800 dark:hover:text-indigo-400',
            //   !isActive && 'hover:border-indigo-300 dark:hover:border-indigo-700',
            // Active states for parent item
            isActive && 'bg-slate-100 dark:bg-slate-900',
            isActive && 'text-indigo-800 dark:text-indigo-400',
            isActive && '[&]:border-indigo-600 dark:border-indigo-400',
            // Conditional class
            { 'has-children': item.children }
          )}
        >
          {item.icon}
          {item.label}
        </div>
      )}
    </NavLink>
  )
}
