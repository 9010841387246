import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { getInputStyles } from '../../../components/Form/styles'
import classnames from 'classnames'

const tags = ['Billing', 'Interaction', 'Issue', 'Resolved', 'Unresolved']

function ComboboxMultiselect({ label, placeholder, onChange }: { label: string; placeholder: string; onChange: (val: string[]) => void }) {
  const [selectedTags, setSelectedTags] = useState([])
  const [query, setQuery] = useState('')

  const filteredTags =
    query === ''
      ? tags
      : tags.filter((tag) => {
          return tag.toLowerCase().includes(query.toLowerCase())
        })

  useEffect(() => {
    onChange(selectedTags)
    setQuery('')
  }, [selectedTags])

  return (
    <Combobox
      multiple
      as="div"
      value={selectedTags}
      onChange={(newValue) => {
        setSelectedTags(newValue)
      }}
    >
      <Label className="block text-sm font-medium text-slate-700 dark:text-slate-300 truncate text-left">{label}</Label>
      <div className="relative ">
        <ComboboxInput
          aria-label="Assignees"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={() => selectedTags.join(', ')}
          placeholder={placeholder}
          className={classnames(getInputStyles())}
        />
        <ComboboxButton onClick={() => setQuery('')} className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
          {filteredTags.map((tag) => (
            <ComboboxOption
              key={tag}
              value={tag}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
            >
              <span className="block truncate group-data-[selected]:font-semibold">{tag}</span>
              <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 dark:text-indigo-400 group-data-[selected]:flex group-data-[focus]:text-white">
                <CheckIcon className="size-5" aria-hidden="true" />
              </span>
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </div>
    </Combobox>
  )
}

export default ComboboxMultiselect
