import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import classnames from "classnames";
import { getInputStyles } from './styles'

export type SelectProps = React.ComponentProps<'select'> & {
  field: {
    name: string
    value?: string
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
  }
  form: { touched: Record<string, boolean>; errors: Record<string, string> }
  label?: string
  options: Record<string, string>
  className?: string
  disabled?: boolean
  required?: boolean
  description?: string
}

export const Select: React.FC<SelectProps> = (props) => {
  const {
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    options,
    className,
    children,
    disabled,
    required,
    description,
    ...rest
  } = props

  const error = errors[field.name] !== undefined && touched[field.name] ? errors[field.name] : undefined

  return (
    <div className={className}>
      {label !== undefined && (
        <label
          htmlFor={`select-${field.name}`}
          className={classnames('block text-sm font-medium text-slate-700 dark:text-slate-200 truncate text-left', description != null ? 'h-9' : 'h-5')}
        >
          <div className="flex flex-col">
            <div>
              {label}
              {required === true && <span className="ml-1 text-sm text-red-500">*</span>}
            </div>
            {description != null && <span className="text-xs text-gray-400 dark:text-gray-500">{description}</span>}
          </div>
        </label>
      )}

      <div className="relative">
        <select
          id={`select-${field.name}`}
          className={classnames(getInputStyles({ disabled, error: error != undefined }))}
          autoComplete="off"
          {...field}
          {...rest}
          disabled={disabled}
        >
          {children ??
            Object.entries(options).map((entry, index) => (
              <option value={entry[0]} key={index}>
                {entry[1]}
              </option>
            ))}
        </select>
        {error !== undefined && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error !== undefined && (
        <p className="mt-1 text-xs text-red-600" id="email-error">
          {error}
        </p>
      )}
    </div>
  )
}

export default Select;
