import axios from 'axios'
import { customAlphabet } from 'nanoid'

import type { TenantRegistration, Invites, Invite } from '../types/registration'
import { Paginated } from './types'

export interface FetchAllTenantRegistrationRecordsArgs {
  businessName?: string
  propertyName?: string
  page?: number
  resultsPerPage?: number
}

export const fetchAllTenantRegistrationRecords = async (params?: FetchAllTenantRegistrationRecordsArgs): Promise<Paginated<TenantRegistration>> => {
  const instance = axios.create()

  const url = '/api/v1/admin/tesa/feed'

  interface ResponseData {
    results: Array<{
      id: string
      tenantId?: string
      feedData: {
        isComplete: boolean
        tenant: {
          businessName: string
          businessType: string | null
          badgeStickerType: string | null
          approximateSqft: string | null
          authorizeSpecificAccountsOnly?: boolean | null
          utilityAccountType?: 'BUSINESS' | 'INDIVIDUAL' | null
          ein?: string | null
          ssnLastFour?: string | null
          utilityAccounts: Array<{
            name: string
            number: string
            tenantType: 'National' | 'Local'
            billingAddress: {
              street1: string
              street2: string | null
              city: string
              state: string
              zip: string
            }
            serviceAgreements: Array<{
              serviceAgreementId: string
              meterNumber: string
              tariffSchedule: string
              serviceAddress: {
                street1: string
                street2: string | null
                city: string
                state: string
                zip: string
              }
              approximateEnergyUsage: string | null
            }>
          }>
        }
        contact: {
          title: string | null
          firstName: string
          lastName: string
          email: string
          phone: string
          department: string | null
        }
        utility: string
        property: string
        version: string
      }
      invites: Invite[]
    }>
    total: number
    resultsPerPage: number
    page: number
  }

  const response = await instance
    .request<ResponseData>({
      method: 'GET',
      url,
      params,
    })
    .then((res) => res.data)

  const results: TenantRegistration[] = response.results.map((registration) => {
    const onboardingRegistrationInvite = registration.invites.find((invite) => invite.type === 'ONBOARDING_REGISTRATION')
    const accountSignupInvite = registration.invites.find((invite) => invite.type === 'ACCOUNT_SIGNUP')

    const isOnboardingRegistrationInviteConsumed = onboardingRegistrationInvite?.consumed ?? false
    const onboardingRegistrationInviteAgreements = onboardingRegistrationInvite?.agreements ?? []

    const invites = {
      ONBOARDING_REGISTRATION: onboardingRegistrationInvite as Invite,
      ACCOUNT_SIGNUP: accountSignupInvite as Invite,
      isOnregInviteCreated: !isOnboardingRegistrationInviteConsumed && onboardingRegistrationInviteAgreements.length === 0,
      isOnregInvitePending: !isOnboardingRegistrationInviteConsumed && onboardingRegistrationInviteAgreements.length > 0,
      isOnregAgreedTesa:
        onboardingRegistrationInviteAgreements.length === 1 &&
        onboardingRegistrationInviteAgreements.find((agreement) => agreement.type === 'TESA') !== undefined,
      isOnregAgreedTesaSupplement:
        onboardingRegistrationInviteAgreements.length === 2 &&
        onboardingRegistrationInviteAgreements.find((agreement) => agreement.type === 'TESA_SUPPLEMENT') !== undefined,
      isOnregAgreedPricingAndPayments:
        onboardingRegistrationInviteAgreements.length === 3 &&
        onboardingRegistrationInviteAgreements.find((agreement) => agreement.type === 'PRICING_AND_PAYMENT_TERMS') !== undefined,
      isOnregInviteConsumed: isOnboardingRegistrationInviteConsumed,
      isAccountInviteCreated: accountSignupInvite !== undefined,
      isAccountInviteConsumed: accountSignupInvite?.consumed === true,
    }

    return {
      id: registration.id,
      tenantId: registration.tenantId,
      isNational: registration.feedData.tenant?.utilityAccounts?.some((ua: any) => ua.tenantType?.toLowerCase() === 'national'),
      tenant: {
        name: registration.feedData.tenant.businessName ?? '',
        type: registration.feedData.tenant.businessType ?? '',
        authorizeSpecificAccountsOnly: registration.feedData.tenant.authorizeSpecificAccountsOnly,
        utilityAccountType: registration.feedData.tenant.utilityAccountType,
        ein: registration.feedData.tenant.ein,
        ssnLastFour: registration.feedData.tenant.ssnLastFour,
        contact: {
          title: registration.feedData.contact.title ?? '',
          firstName: registration.feedData.contact.firstName ?? '',
          lastName: registration.feedData.contact.lastName ?? '',
          emailAddress: registration.feedData.contact.email ?? '',
          phoneNumber: registration.feedData.contact.phone ?? '',
          department: registration.feedData.contact.department ?? '',
        },
        accounts: registration.feedData.tenant?.utilityAccounts?.map((account) => {
          return {
            name: account.name ?? '',
            number: account.number ?? '',
            tenantType: account.tenantType,
            billingAddress: {
              address: account.billingAddress.street1 ?? '',
              address2: account.billingAddress.street2 ?? '',
              city: account.billingAddress.city ?? '',
              state: account.billingAddress.state ?? '',
              zipCode: account.billingAddress.zip ?? '',
            },
            agreements: account.serviceAgreements.map((agreement) => {
              return {
                number: agreement.serviceAgreementId ?? '',
                meterNumber: agreement.meterNumber ?? '',
                tariffSchedule: agreement.tariffSchedule ?? '',
                serviceAddress: {
                  address: agreement.serviceAddress.street1 ?? '',
                  address2: agreement.serviceAddress.street2 ?? '',
                  city: agreement.serviceAddress.city ?? '',
                  state: agreement.serviceAddress.state ?? '',
                  zipCode: agreement.serviceAddress.zip ?? '',
                },
                approximateEnergyUsage: agreement.approximateEnergyUsage ?? '',
              }
            }),
          }
        }),
        approximateSurfaceArea: registration.feedData.tenant.approximateSqft ?? '',
        badge: registration.feedData.tenant.badgeStickerType,
      },
      utility: registration.feedData.utility,
      property: registration.feedData.property,
      version: registration.feedData.version,
      status: 'draft',
      invites,
      isComplete: !!registration.feedData.isComplete,
    }
  })

  return {
    ...response,
    results,
  }
}

export const previewTenantRegistrationRecords = async (file: File): Promise<TenantRegistration[]> => {
  const instance = axios.create()

  const url = '/api/v1/admin/tesa/feed/upload'

  const formData = new FormData()
  formData.set('file', file)
  formData.set('shouldPersist', '')
  // formData.set('utilityCompany', 'PG&E')

  interface ResponseData {
    registrationRecords: Array<{
      tenant: {
        businessName: string
        businessType: string | null
        badgeStickerType: string | null
        approximateSqft: string | null
        authorizeSpecificAccountsOnly?: boolean | null
        ein?: string | null
        ssnLastFour?: string | null
        utilityAccountType?: 'BUSINESS' | 'INDIVIDUAL' | null
        utilityAccounts: Array<{
          name: string
          number: string
          tenantType: 'National' | 'Local'
          billingAddress: {
            street1: string
            street2: string | null
            city: string
            state: string
            zip: string
          }
          serviceAgreements: Array<{
            serviceAgreementId: string
            meterNumber: string
            tariffSchedule: string
            serviceAddress: {
              street1: string
              street2: string | null
              city: string
              state: string
              zip: string
            }
            approximateEnergyUsage: string | null
          }>
        }>
      }
      contact: {
        title: string | null
        firstName: string
        lastName: string
        email: string
        phone: string
        department: string | null
      }
      utility: string
      property: string
      version: string
      invites: Invites
      isComplete: boolean
    }>
  }

  const response = await instance.request<ResponseData>({
    method: 'POST',
    url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  const nanoid = customAlphabet('0123456789abcdef', 10)
  const registrations: TenantRegistration[] = response.data.registrationRecords.map((recordData) => {
    return {
      id: `draft-${nanoid()}`,
      tenant: {
        name: recordData.tenant.businessName,
        type: recordData.tenant.businessType,
        authorizeSpecificAccountsOnly: recordData.tenant.authorizeSpecificAccountsOnly,
        utilityAccountType: recordData.tenant.utilityAccountType,
        ein: recordData.tenant.ein,
        ssnLastFour: recordData.tenant.ssnLastFour,
        contact: {
          title: recordData.contact.title,
          firstName: recordData.contact.firstName,
          lastName: recordData.contact.lastName,
          emailAddress: recordData.contact.email,
          phoneNumber: recordData.contact.phone,
          department: recordData.contact.department,
        },
        accounts: recordData.tenant.utilityAccounts.map((accountData) => {
          return {
            name: accountData.name,
            number: accountData.number,
            tenantType: accountData.tenantType,
            billingAddress: {
              address: accountData.billingAddress.street1,
              address2: accountData.billingAddress.street2,
              city: accountData.billingAddress.city,
              state: accountData.billingAddress.state,
              zipCode: accountData.billingAddress.zip,
            },
            agreements: accountData.serviceAgreements.map((agreementData) => {
              return {
                number: agreementData.serviceAgreementId,
                meterNumber: agreementData.meterNumber,
                tariffSchedule: agreementData.tariffSchedule,
                serviceAddress: {
                  address: agreementData.serviceAddress.street1,
                  address2: agreementData.serviceAddress.street2,
                  city: agreementData.serviceAddress.city,
                  state: agreementData.serviceAddress.state,
                  zipCode: agreementData.serviceAddress.zip,
                },
                approximateEnergyUsage: agreementData.approximateEnergyUsage,
              }
            }),
          }
        }),
        approximateSurfaceArea: recordData.tenant.approximateSqft,
        badge: recordData.tenant.badgeStickerType,
      },
      utility: recordData.utility,
      property: '',
      version: '',
      status: 'draft',
      invites: recordData.invites,
    }
  })

  return registrations
}

export const importTenantRegistrationRecords = async (file: File): Promise<TenantRegistration[]> => {
  const instance = axios.create()

  const url = '/api/v1/admin/tesa/feed/upload'

  const formData = new FormData()
  formData.set('file', file)
  formData.set('shouldPersist', 'true')
  formData.set('propertyName', 'King Property')
  // formData.set('utilityCompany', 'PG&E')

  const response = await instance.request<{ registrationRecords: TenantRegistration[] }>({
    method: 'POST',
    url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response.data.registrationRecords
}

export const updateTenantRegistrationRecord = async (
  id: string,
  data: Omit<TenantRegistration, 'id'>
): Promise<
  | {
      result: 'success'
      registration: TenantRegistration
    }
  | {
      result: 'not-found'
    }
  | {
      result: 'bad-request'
    }
> => {
  const instance = axios.create()

  const url = `/api/v1/admin/tesa/feed/${id}`

  interface ResponseData {
    id: string
    tenantId?: string
    feedData: {
      isComplete: boolean
      tenant: {
        businessName: string
        businessType: string | null
        badgeStickerType: string | null
        approximateSqft: string | null
        authorizeSpecificAccountsOnly?: boolean | null
        ein?: string | null
        ssnLastFour?: string | null
        utilityAccountType?: 'BUSINESS' | 'INDIVIDUAL' | null
        utilityAccounts: Array<{
          name: string
          number: string
          tenantType: 'National' | 'Local'
          billingAddress: {
            street1: string
            street2: string | null
            city: string
            state: string
            zip: string
          }
          serviceAgreements: Array<{
            serviceAgreementId: string
            meterNumber: string
            tariffSchedule: string
            serviceAddress: {
              street1: string
              street2: string | null
              city: string
              state: string
              zip: string
            }
            approximateEnergyUsage: string | null
          }>
        }>
      }
      contact: {
        title: string | null
        firstName: string
        lastName: string
        email: string
        phone: string
        department: string | null
      }
      utility: string
      property: string
      version: string
      invites: Invites
    }
  }

  const response = await instance.request<ResponseData | { statusCode: 400; message: string[] } | { statusCode: 404; message: string }>({
    method: 'PUT',
    url,
    data: {
      id,
      tenantId: data.tenantId,
      tenant: {
        businessName: data.tenant.name,
        businessType: data.tenant.type,
        authorizeSpecificAccountsOnly: data.tenant.authorizeSpecificAccountsOnly,
        utilityAccountType: data.tenant.utilityAccountType,
        ein: data.tenant.ein,
        ssnLastFour: data.tenant.ssnLastFour,
        badgeStickerType: data.tenant.badge,
        approximateSqft: data.tenant.approximateSurfaceArea,
        utilityAccounts: data.tenant.accounts.map((accountData) => {
          return {
            name: accountData.name,
            number: accountData.number,
            tenantType: accountData.tenantType,
            billingAddress: {
              street1: accountData.billingAddress.address,
              street2: accountData.billingAddress.address2,
              city: accountData.billingAddress.city,
              state: accountData.billingAddress.state,
              zip: accountData.billingAddress.zipCode,
            },
            serviceAgreements: accountData.agreements.map((agreementData) => {
              return {
                serviceAgreementId: agreementData.number,
                meterNumber: agreementData.meterNumber,
                tariffSchedule: agreementData.tariffSchedule,
                serviceAddress: {
                  street1: agreementData.serviceAddress.address,
                  street2: agreementData.serviceAddress.address2,
                  city: agreementData.serviceAddress.city,
                  state: agreementData.serviceAddress.state,
                  zip: agreementData.serviceAddress.zipCode,
                },
                approximateEnergyUsage: agreementData.approximateEnergyUsage,
              }
            }),
          }
        }),
      },
      contact: {
        title: data.tenant.contact.title,
        firstName: data.tenant.contact.firstName,
        lastName: data.tenant.contact.lastName,
        email: data.tenant.contact.emailAddress,
        phone: data.tenant.contact.phoneNumber,
        department: data.tenant.contact.department,
      },
      utility: data.utility,
      property: data.property,
      version: data.version,
      isComplete: data.isComplete,
    },
  })

  if ('statusCode' in response.data) {
    if (response.data.statusCode === 404) {
      return { result: 'not-found' }
    } else if (response.data.statusCode === 400) {
      return { result: 'bad-request' }
    }
  }

  const { id: _id, feedData: recordData } = response.data

  const registration: TenantRegistration = {
    id: _id,
    tenant: {
      name: recordData.tenant.businessName ?? '',
      type: recordData.tenant.businessType ?? '',
      authorizeSpecificAccountsOnly: recordData.tenant.authorizeSpecificAccountsOnly,
      utilityAccountType: recordData.tenant.utilityAccountType,
      ein: recordData.tenant.ein,
      ssnLastFour: recordData.tenant.ssnLastFour,
      contact: {
        title: recordData.contact.title ?? '',
        firstName: recordData.contact.firstName ?? '',
        lastName: recordData.contact.lastName ?? '',
        emailAddress: recordData.contact.email ?? '',
        phoneNumber: recordData.contact.phone ?? '',
        department: recordData.contact.department ?? '',
      },
      accounts: recordData.tenant.utilityAccounts.map((accountData) => {
        return {
          name: accountData.name ?? '',
          number: accountData.number ?? '',
          tenantType: accountData.tenantType ?? '',
          billingAddress: {
            address: accountData.billingAddress.street1 ?? '',
            address2: accountData.billingAddress.street2 ?? '',
            city: accountData.billingAddress.city ?? '',
            state: accountData.billingAddress.state ?? '',
            zipCode: accountData.billingAddress.zip ?? '',
          },
          agreements: accountData.serviceAgreements.map((agreementData) => {
            return {
              number: agreementData.serviceAgreementId ?? '',
              meterNumber: agreementData.meterNumber ?? '',
              tariffSchedule: agreementData.tariffSchedule ?? '',
              serviceAddress: {
                address: agreementData.serviceAddress.street1 ?? '',
                address2: agreementData.serviceAddress.street2 ?? '',
                city: agreementData.serviceAddress.city ?? '',
                state: agreementData.serviceAddress.state ?? '',
                zipCode: agreementData.serviceAddress.zip ?? '',
              },
              approximateEnergyUsage: agreementData.approximateEnergyUsage ?? '',
            }
          }),
        }
      }),
      approximateSurfaceArea: recordData.tenant.approximateSqft,
      badge: recordData.tenant.badgeStickerType,
    },
    utility: recordData.utility,
    property: recordData.property,
    version: recordData.version,
    status: 'draft',
    invites: recordData.invites,
    isComplete: !!recordData.isComplete,
  }

  return { result: 'success', registration }
}

export const markRegistrationCompleteApi = async (id: string): Promise<unknown> => {
  return await axios.put(`/api/v1/admin/tesa/feed/${id}/complete`)
}

export const exportDataApi = async (propertyName: string, type: string): Promise<string> => {
  return await axios
    .get(`/api/v1/admin/tesa/feed/invite/property/${propertyName}/links`, {
      params: { type },
      responseType: 'arraybuffer',
    })
    .then((res) => {
      return window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }))
    })
}

export const exportZipDataApi = async (propertyName: string, type: string): Promise<string> => {

  if (type !== 'TESA_EXPORT') {
    throw new Error('Invalid type: ' + type + '. Only TESA_EXPORT is supported.');
  }

  return await axios
    .get(`/api/v1/admin/tesa/feed/invite/property/${propertyName}/tesa`, {
      params: { type },
      responseType: 'blob',
    })
    .then((res) => {
      return window.URL.createObjectURL(new Blob([res.data], { type: 'application/zip' }))
    })
}

export const getPropertyListApi = async (): Promise<string[]> => {
  return await axios.get(`/api/v1/admin/tesa/feed/property`).then((res) => res.data as string[])
}

export const getTESASupplementDocumentApi = async (agreementId: string): Promise<string> => {
  return await axios
    .get(`/api/v1/admin/tesa/feed/agreement/${agreementId}/submission/view`, {
      responseType: 'arraybuffer',
    })
    .then((res) => window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' })))
}

export const updateTESASupplementAgreementStatusApi = async (agreementId: string, action: 'ACCEPTED' | 'REJECTED'): Promise<unknown> => {
  return await axios.put(`/api/v1/admin/tesa/feed/agreement/${agreementId}/submission`, { action })
}
