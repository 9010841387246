import { CheckIcon, TagIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'flowbite-react'
import { useState } from 'react'
import { Note } from '../../../apis/types'
import classnames from 'classnames'

const tags = ['Billing', 'Interaction', 'Issue', 'Resolved', 'Unresolved']

const TagChooser = ({ note, onUpdate }: { note: Note; onUpdate: (cuid: string, body: string, tags: string[]) => void }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={classnames(
          'text-xs p-1 rounded-md hover:bg-indigo-200 dark:hover:bg-indigo-500/50 text-indigo-500 hover:text-indigo-400 dark:text-indigo-100 dark:hover:text-indigo-200',
          {
            'bg-indigo-200 dark:bg-indigo-500/50': isOpen,
          }
        )}
      >
        <Tooltip
          content="Tag"
          arrow={false}
          className="text-xs z-10 pointer-events-none select-none bg-slate-600 dark:bg-slate-950 border dark:border-0 dark:border-0"
        >
          <TagIcon className="size-4" />
        </Tooltip>
      </button>
      {isOpen && (
        <div
          className="relative z-10"
          onMouseLeave={(event) => {
            event.preventDefault()
            event.stopPropagation()
            setIsOpen(false)
          }}
        >
          <div className="absolute top-2 -left-1 w-fit bg-slate-200 dark:bg-slate-600 rounded-md w-[116px] shadow-lg shadow-slate-400/20 dark:shadow-slate-900/30">
            {tags.map((tag) => (
              <a
                href="#"
                onClick={() => {
                  onUpdate(note.cuid, note.body, note.tags.includes(tag) ? note.tags.filter((t) => t !== tag) : [...note.tags, tag])
                }}
                className="block px-2 py-1 text-xs text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-slate-700 hover:text-gray-900 dark:hover:text-gray-100 data-[focus]:bg-gray-100 dark:data-[focus]:bg-slate-700 data-[focus]:text-gray-900 dark:data-[focus]:text-gray-100 data-[focus]:outline-none first:rounded-t-md last:rounded-b-md last:rounded-r-md"
              >
                <div className="flex items-center gap-2">
                  {note.tags.includes(tag) ? <CheckIcon className="inline-block size-3" /> : <div className="inline-block size-3" />}
                  {tag}
                </div>
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
export default TagChooser
