import React, { useState } from "react";
import { updateTenantPreferenceApi } from "../../../../apis/apis";
import { getTenant } from "../Tenants.slice";
import { pushToast } from "../../../../components/Toaster/Toaster.slice";
import { useParams } from "react-router-dom";
import Input from '../../../../components/Form/Input'
import Button from "../../../../components/Form/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup'
import { usStates } from '../../../../util/form'
import { formatPostalCode } from '../../../../util/string'
import Modal from '../../../../components/Modal'
import { ServiceAccount } from '../../../../apis/types'
import { useAppDispatch } from '../../../../shared/redux/hooks'
import ControlledToggle from '../../../../components/Form/ControlledToggle'
import ComboboxAutocomplete from '../../../../components/Form/ComboboxAutocomplete'

export interface TabMailingPreferenceProps {
  title: string
  data: ServiceAccount
}

export const TabMailingPreference: React.FC<TabMailingPreferenceProps> = (props) => {
  const dispatch = useAppDispatch()
  const { tenantId } = useParams()
  const {
    id: tenantAccountId,
    mailingPreference: {
      mailingAddress: { street1, street2, city, state, zip },
      recipientName,
      paperless,
    },
  } = props.data
  const [isEditing, setEditing] = useState(false)
  const [confirmModal, setConfirmModal] = useState<React.ReactNode>(false)

  return (
    <div className="grid grid-cols-2 gap-6">
      <Formik
        initialValues={{
          recipientName: recipientName ?? '',
          street1: street1 ?? '',
          street2: street2 ?? '',
          city: city ?? '',
          state: state ?? '',
          zip: zip ?? '',
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          recipientName: Yup.string().required('Required field'),
          street1: Yup.string().required('Required field'),
          city: Yup.string().required('Required field'),
          state: Yup.string().required('Required field'),
          zip: Yup.string()
            .required('Required field')
            .test('range', '5 or 9 digits', (value: any) => {
              return value?.replace(/\D/g, '').length === 5 || value?.replace(/\D/g, '').length === 9
            })
            .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Invalid format'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await new Promise((resolve, reject) => {
              setConfirmModal(<Modal resolve={resolve} reject={reject} />)
            })
            setConfirmModal(false)

            try {
              void updateTenantPreferenceApi({
                preferenceType: 'paperless',
                tenantAccountId,
                ...values,
              })
              dispatch(pushToast({ message: 'Mailing address updated' }))
              dispatch(getTenant(tenantId ?? ''))
            } catch (e: any) {
              dispatch(
                pushToast({
                  type: 'error',
                  message: 'Error updating mailing address',
                  description: `${e.response?.data?.message !== undefined ? `${e.response?.data?.message as string}:` : 'Error:'} ${e.message as string}`,
                })
              )
            }
            setSubmitting(false)
          } catch (e) {
            setConfirmModal(false)
          }
        }}
      >
        {({ values, errors, touched, isSubmitting, isValidating, isValid, handleBlur, validateField, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-6 gap-3">
              <Field
                as={Input}
                name="recipientName"
                label="Recipient name"
                className="col-span-4"
                error={errors.recipientName !== undefined && (touched.recipientName ?? false) ? errors.recipientName : undefined}
              />
              <ControlledToggle
                label="Paperless"
                className="mt-4 relative inline-flex shrink-0 col-span-2"
                checked={paperless}
                disabled={isEditing}
                onChange={(editedValue) => {
                  async function init(): Promise<void> {
                    try {
                      await new Promise((resolve, reject) => {
                        setConfirmModal(<Modal resolve={resolve} reject={reject} />)
                      })
                      setConfirmModal(false)

                      setEditing(true)
                      try {
                        await updateTenantPreferenceApi({
                          preferenceType: 'paperless',
                          tenantAccountId,
                          enabled: editedValue,
                        })
                        dispatch(getTenant(tenantId ?? ''))
                        dispatch(
                          pushToast({
                            message: `Paperless ${editedValue !== undefined ? 'enabled' : 'disabled'}`,
                          })
                        )
                        setEditing(false)
                      } catch (e: any) {
                        dispatch(
                          pushToast({
                            type: 'error',
                            message: `Error updating paperless preference`,
                            description: `${e.response?.data?.message !== undefined ? `${e.response?.data?.message as string}:` : 'Error:'} ${
                              e.message as string
                            }`,
                          })
                        )
                        setEditing(false)
                      }
                    } catch (e) {
                      setConfirmModal(false)
                    }
                  }

                  void init()
                }}
              />
              <Field
                as={Input}
                name="street1"
                label="Address line 1"
                className="col-span-4"
                error={errors.street1 !== undefined && (touched.street1 ?? false) ? errors.street1 : undefined}
              />
              <Field
                as={Input}
                name="street2"
                label="Address line 2"
                className="col-span-2"
                error={errors.street2 !== undefined && (touched.street2 ?? false) ? errors.street2 : undefined}
              />
              <Field
                as={Input}
                name="city"
                label="City"
                className="col-span-2"
                error={errors.city !== undefined && (touched.city ?? false) ? errors.city : undefined}
              />
              <Field
                as={ComboboxAutocomplete}
                name="state"
                label="State"
                className="col-span-2"
                error={errors.state !== undefined && (touched.state ?? false) ? errors.state : undefined}
                data={Object.entries(usStates).map((item) => ({
                  id: item[0],
                  name: item[1],
                }))}
                value={values.state}
                handleChange={(value: any) => {
                  const stateValue = typeof value === 'object' ? value.name : value
                  setFieldValue('state', stateValue)
                }}
              />
              <Field
                as={Input}
                name="zip"
                label="Zip code"
                className="col-span-2"
                onBlur={(event: any) => {
                  setFieldValue('zip', formatPostalCode(values.zip))
                  handleBlur(event)
                  setImmediate(() => {
                    validateField('zip')
                  })
                }}
                error={errors.zip !== undefined && (touched.zip ?? false) ? errors.zip : undefined}
              />
              <Button type="submit" className="mt-3 w-full col-span-6" disabled={isSubmitting || (isValidating && !isValid)}>
                Update mailing address
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {confirmModal}
    </div>
  )
}

export default TabMailingPreference;
