import React, { useState, useEffect, useRef } from "react";
import { pushToast } from "../Toaster/Toaster.slice";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import classnames from "classnames";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAppDispatch } from "../../shared/redux/hooks";
import Input from './Input'

export interface InlineEditInputProps {
  name: string
  label: string
  required?: boolean
  validationSchema?: Yup.StringSchema
  initialValue?: string
  className?: string
  hideLabel?: boolean
  linkTemplate?: string
  formatter?: (value: string) => string
  handleSubmit: (value: string | null) => Promise<void>
}

export const InlineEditInput: React.FC<InlineEditInputProps> = (props) => {
  const { name, label, validationSchema, required, initialValue, handleSubmit, className, hideLabel, linkTemplate, formatter } = props
  const dispatch = useAppDispatch()
  const [isEditing, setEditing] = useState(false)
  const [editedValue, setEditedValue] = useState<string | undefined>(initialValue)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setEditedValue(initialValue)
  }, [initialValue])

  const useOutsideAlerter = (ref: React.MutableRefObject<HTMLDivElement | null>): void => {
    useEffect(() => {
      function handleClickOutside(event: React.MouseEvent): void {
        if (ref.current !== null && !ref.current.contains(event.target as Node)) {
          setEditing(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside as unknown as (this: Document, ev: MouseEvent) => void)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside as unknown as (this: Document, ev: MouseEvent) => void)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  return (
    <div className={classnames(className)}>
      {!(hideLabel ?? false) && (
        <label htmlFor="email" className="h-5 block text-sm font-medium text-slate-700 dark:text-slate-300">
          {label}
        </label>
      )}

      {!isEditing && (
        <div
          className={classnames('mt-1 h-10 px-1 -ml-1 flex flex-col justify-center hover:bg-indigo-50 dark:hover:bg-indigo-900 transition-colors rounded-md')}
          onClick={() => setEditing(true)}
        >
          <div className="flex">
            {editedValue !== undefined && (
              <span className="mr-1 truncate">
                {linkTemplate ? (
                  <a
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    target="_blank"
                    className="underline hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors"
                    href={linkTemplate.replace('@@value@@', editedValue)}
                  >
                    {editedValue}
                  </a>
                ) : (
                  editedValue
                )}
              </span>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="shrink-0 h-3.5 w-3.5 mt-0.5 self-center text-indigo-500 dark:text-indigo-400 stroke-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      )}

      {isEditing && (
        <Formik
          initialValues={{
            [name]: editedValue,
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            [name]: Yup.string()
              .concat(
                Yup.string()
                  .trim('Must be trimmed')
                  .required(() => (required ?? false ? 'Required' : true))
              )
              .concat(validationSchema ?? Yup.string()),
          })}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            if (formatter !== undefined) {
              setFieldValue(name, formatter(values[name] ?? ''))
              values[name] = formatter(values[name] ?? '')
            }
            try {
              await handleSubmit(values[name] ?? null)
              setEditedValue(values[name])
              dispatch(pushToast({ message: `${label} updated` }))
              setEditing(false)
            } catch (error) {
              dispatch(
                pushToast({
                  type: 'error',
                  message: `Error updating ${label}`,
                  description: `${
                    (error as XMLHttpRequest).response?.data?.message !== undefined
                      ? `${(error as XMLHttpRequest).response?.data?.message as string}:`
                      : 'Error:'
                  } ${(error as { message: string }).message as string}`,
                })
              )
            }
            setSubmitting(false)
          }}
        >
          {({ values, errors, touched, setFieldValue, handleBlur, validateField }) => (
            <Form>
              <div ref={wrapperRef} className="relative rounded-md">
                <Field
                  as={Input}
                  type="text"
                  name={name}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Escape') {
                      setEditing(false)
                    }
                  }}
                  className={classnames('pt-1.5 px-0', className)}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    if (formatter !== undefined) {
                      setFieldValue(name, formatter(values[name] ?? ''))
                      handleBlur(event)
                      setImmediate(() => {
                        validateField(name)
                      })
                    }
                  }}
                  autoComplete="off"
                  autoFocus
                />
                {/* Added "top-2" for consistent vertical positioning further down from input component */}
                <div className="flex justify-end absolute right-0 top-12 z-50">
                  <div className="relative">
                    <button
                      type="button"
                      onClick={() => {
                        setEditing(false)
                      }}
                      className="ml-1.5 border border-slate-200 dark:border-slate-700 bg-slate-100 dark:bg-slate-800 hover:bg-slate-200 dark:hover:bg-slate-700 text-slate-400 dark:text-slate-500 active:bg-slate-300 dark:active:bg-slate-600 shadow-md rounded transition-colors"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    <button
                      type="submit"
                      className="ml-1.5 border border-slate-200 dark:border-slate-700 bg-slate-100 dark:bg-slate-800 hover:bg-slate-200 dark:hover:bg-slate-700 text-indigo-500 dark:text-indigo-600 active:bg-indigo-200 dark:active:bg-indigo-700 shadow-md rounded transition-colors"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </button>
                  </div>
                </div>
                {errors[name] !== undefined && touched[name] && errors[name] && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                )}
              </div>
              {errors[name] !== undefined && touched[name] && (
                <p className="mt-1 text-xs text-red-600 dark:text-red-400 mr-[63px] whitespace-normal" id="email-error">
                  {errors[name]}
                </p>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default InlineEditInput;
