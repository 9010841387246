import React, { ChangeEvent } from "react";
import classnames from "classnames";
import Input from '../Form/Input'

export interface ControlledQuickSearchProps {
  title: string;
  value: string | undefined;
  placeholder?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ControlledQuickSearch: React.FC<ControlledQuickSearchProps> = (props) => {
  const { title, value = "", placeholder, handleChange } = props;
  return (
    <div className="w-full">
      <label htmlFor="search" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
        {title}
      </label>
      <Input
        type="text"
        name="search"
        id="search"
        value={value}
        onChange={(e) => handleChange(e)}
        placeholder={placeholder}
        className={classnames({
          'bg-indigo-50 dark:bg-indigo-900/70': value,
          'focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-indigo-500 dark:focus:border-indigo-400': value !== undefined,
        })}
      />
    </div>
  )
};

export default ControlledQuickSearch;
