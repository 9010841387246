import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { store } from "./shared/redux/store.ts";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import System from "./features/system/System";
import TenantOnboarding from "./features/tenant/onboarding/Onboarding";
import TESASupplement from "./features/tenant/onboarding/TESASupplement";
import Tenants from "./features/tenant/tenant/Tenants";
import Tenant from "./features/tenant/tenant/Tenant";
import EditTenant from "./features/tenant/tenant/EditTenant";
import Impersonate from "./features/impersonate/Impersonate";
import Invoices from "./features/collections/invoices/Invoices";
import Payments from "./features/collections/payments/Payments";
import FileExplorer from "./features/file_explorer/FileExplorer";
import Programs from "./features/programs/Programs";
import Program from "./features/programs/Program";
import UsersPage from "./features/users/pages/users/UsersPage.tsx";
import UserPage from "./features/users/pages/user/UserPage.tsx";
import App from "./App.tsx";
import NoteSearch from './features/notes/Notes.tsx'

const {
  VITE_AUTH0_DOMAIN: AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID: AUTH0_CLIENT_ID,
  VITE_AUTH0_AUDIENCE: AUTH0_AUDIENCE,
  VITE_AUTH0_SCOPE: AUTH0_SCOPE,
} = import.meta.env

const router = createBrowserRouter([
  {
    path: '/impersonate',
    element: <Impersonate />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/tenant/onboarding/TESAsupplement/:agreementId',
    element: <TESASupplement />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/tenant/onboarding/TESA/:agreementId',
    element: <TESASupplement />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <System />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/tenant/onboarding',
        element: <TenantOnboarding />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/tenant/tenants',
        element: <Tenants />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/tenant/tenants',
        element: <Tenants />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/tenant/tenants/:tenantId',
        element: <Tenant />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/tenant/tenants/:tenantId/edit',
        element: <EditTenant />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/collections/payments',
        element: <Payments />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/collections/invoices',
        element: <Invoices />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/users',
        element: <UsersPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/users/:userID',
        element: <UserPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/file-explorer',
        element: <FileExplorer />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/program/programs',
        element: <Programs />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/program/programs/:programId',
        element: <Program />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/notes',
        element: <NoteSearch />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        authorizationParams={{
          audience: AUTH0_AUDIENCE,
          scope: AUTH0_SCOPE,
          redirect_uri: window.location.origin,
        }}
        cacheLocation="localstorage"
      >
        <RouterProvider router={router} />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);
