import React from 'react'
import IconKing from './icons/icon-king'
import Menu from './Menu'

const Nav: React.FC = () => {
  return (
    <div className="flex flex-col flex-[0.1] h-screen bg-slate-200 dark:bg-slate-800 border-0 border-gray-200 dark:border-0 max-w-min text-gray-500 dark:text-gray-400">
      <IconKing className="w-14 h-14 min-h-[56px] m-5 mx-6 inline-block text-indigo-800 dark:text-indigo-400" />
      <Menu />
    </div>
  )
}

export default Nav
