import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import classnames from "classnames";

export interface ToastProps {
  data: {
    type?: 'success' | 'error'
    message?: string
    description?: string
  }
}

export const Toast: React.FC<ToastProps> = (props) => {
  const { type, message, description } = props.data
  const [show, setShow] = useState<boolean>(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false)
    }, 7000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={classnames(
          'z-[1000] max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-5 overflow-hidden',
          {
            'bg-emerald-500 dark:bg-emerald-500': type === 'success' || type == null,
            'bg-red-500 dark:bg-red-500': type === 'error',
          }
        )}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              <CheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-semibold text-white dark:text-white">{message}</p>
              {description !== undefined && <p className="text-xs font-normal text-white dark:text-white">{description}</p>}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default Toast;
