import React from 'react'
import { Switch } from '@headlessui/react'
import classnames from 'classnames'

interface ToggleProps {
  onChange: (isChecked: boolean) => void
  checked: boolean
  label?: string
  className?: string
  disabled?: boolean
}

const ControlledToggle: React.FC<ToggleProps> = ({ checked, label, onChange, className, disabled }) => {
  const handleChange = (): void => {
    onChange(!checked)
  }

  return (
    <Switch.Group as="div" className={classnames('w-full flex items-center justify-between', className)}>
      {label != null && (
        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-700 dark:text-gray-300" passive>
          {label}
        </Switch.Label>
      )}
      <Switch
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        className={classnames(
          'ml-4 relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
          checked ? 'bg-emerald-500 dark:bg-emerald-600' : 'bg-gray-200 dark:bg-gray-700',
          disabled === true ? 'opacity-80 cursor-not-allowed' : ''
        )}
      >
        <span
          aria-hidden="true"
          className={classnames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-gray-200 shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

export default ControlledToggle
