import { useEffect, useState } from 'react'
import Layout from '../system/Layout'
import { useAppDispatch, useAppSelector } from '../../shared/redux/hooks'
import { deleteNote, getNotes, updateNote } from './Notes.slice'
import NoteItem from './components/NoteItem'
import Panel from '../../components/Panel'
import Input from '../../components/Form/Input'
import Datepicker from 'react-tailwindcss-datepicker'
import classnames from 'classnames'
import { getInputStyles } from '../../components/Form/styles'
import Paginator from '../../components/Table/Paginator'
import { useAuth0 } from '@auth0/auth0-react'
import { Note, NoteSearchParams } from '../../apis/types'
import ComboboxMultiselect from './components/ComboboxMultiselect'
import Modal from '../../components/Modal'

const Notes: React.FC = () => {
  const dispatch = useAppDispatch()
  const { sourceEntities, total, resultsPerPage, page } = useAppSelector((state) => state.notes)
  const { user } = useAuth0()
  const [search, setSearch] = useState('')
  const [datePickerValue, setDatePickerValue] = useState<{
    startDate: Date | null
    endDate: Date | null
  }>({
    startDate: null,
    endDate: null,
  })
  const [confirmModal, setConfirmModal] = useState<React.ReactElement | boolean>(false)
  const [filterParams, setFilterParams] = useState<NoteSearchParams>({ sortIsPinned: 'desc', sortCreatedAt: 'desc' })

  useEffect(() => {
    dispatch(getNotes(filterParams))
  }, [dispatch, filterParams])

  const handlePaginatorClick = (page: number): void => {
    setFilterParams({
      ...filterParams,
      page,
    })
  }

  const flatNotes = Array.from(
    new Map(
      Object.values(sourceEntities)
        .flat()
        .map((note) => [note.cuid, note])
    ).values()
  )

  const handleNoteUpdate = async (noteCuid: string, body: string, tags: string[]) => {
    await dispatch(updateNote({ noteCuid, body, tags }))
    dispatch(getNotes(filterParams))
  }

  const handleNoteTogglePin = async (note: Note) => {
    await dispatch(updateNote({ noteCuid: note.cuid, isPinned: !note.isPinned }))
    dispatch(getNotes(filterParams))
  }

  const handleNoteDelete = async (note: Note) => {
    try {
      await new Promise((resolve, reject) => {
        setConfirmModal(<Modal resolve={resolve} reject={reject} />)
      })
      setConfirmModal(false)
      await dispatch(deleteNote({ noteCuid: note.cuid }))
      dispatch(getNotes(filterParams))
    } catch (e) {
      setConfirmModal(false)
    }
  }

  return (
    <Layout title="Notes">
      {confirmModal}
      <Panel header="Filters" className="mb-6">
        <div className="grid grid-cols-3 gap-2">
          <Input
            type="text"
            label="Search"
            placeholder="Search note body"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value)
              setFilterParams({ ...filterParams, body: event.target.value })
            }}
          />
          <ComboboxMultiselect
            label="Tags"
            placeholder="Select tag(s)"
            onChange={(tags: string[]) => {
              setFilterParams({ ...filterParams, tags })
            }}
          />
          <div>
            <label htmlFor="">Date range</label>
            <Datepicker
              value={datePickerValue}
              onChange={(value) => {
                setDatePickerValue(value as { startDate: Date; endDate: Date })
                setFilterParams({
                  ...filterParams,
                  startDate: value?.startDate ? new Date(value.startDate) : undefined,
                  endDate: value?.endDate ? new Date(value.endDate) : undefined,
                })
              }}
              primaryColor="indigo"
              separator="–"
              containerClassName={'text-base font-base text-inherit relative'}
              inputClassName={classnames(getInputStyles(), 'px-2  py-1.5 inline-block')}
              showShortcuts={true}
              maxDate={new Date()}
            />
          </div>
        </div>
      </Panel>
      {flatNotes?.filter((note) => note.isPinned).length > 0 && (
        <>
          <div className="flex flex-row justify-center w-full border-t border-slate-300 dark:border-slate-600 mt-4 -mb-4 text-bg-red-500">
            <span className="border-slate-200 bg-slate-100 dark:bg-slate-900 text-slate-500 dark:text-slate-400 relative -top-2 text-xs px-2 rounded-full">
              Pinned
            </span>
          </div>
          {flatNotes
            ?.filter((note) => note.isPinned)
            .map((note) => (
              <NoteItem
                key={note.cuid}
                canModify={user?.email === note.author.email}
                isLast={false}
                onUpdate={handleNoteUpdate}
                onTogglePin={handleNoteTogglePin}
                onDelete={handleNoteDelete}
                note={note}
                searchTerm={search}
                showSourceEntity={true}
              />
            ))}
          <hr className="my-4 border-slate-300 dark:border-slate-600" />
        </>
      )}
      {flatNotes
        .filter((note) => !note.isPinned)
        .map((note) => (
          <NoteItem
            key={note.cuid}
            canModify={user?.email === note.author.email}
            isLast={false}
            onUpdate={handleNoteUpdate}
            onTogglePin={handleNoteTogglePin}
            onDelete={handleNoteDelete}
            note={note}
            searchTerm={search}
            showSourceEntity={true}
          />
        ))}
      <Paginator className="mt-4" data={{ total, resultsPerPage, page }} handlePaginatorClick={handlePaginatorClick} />
    </Layout>
  )
}

export default Notes
