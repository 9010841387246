import { BookmarkIcon, BookmarkSlashIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { format, formatDistanceToNow } from 'date-fns'
import { Tooltip } from 'flowbite-react'
import { memo, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { getInputStyles } from '../../../components/Form/styles'
import { Note } from '../../../apis/types'
import { Link } from 'react-router-dom'
import TagChooser from './TagChooser'

const NoteItem = memo(
  ({
    note,
    canModify,
    searchTerm,
    showSourceEntity = false,
    onUpdate,
    onDelete,
    onTogglePin,
  }: {
    note: Note
    isLast: boolean
    canModify: boolean
    searchTerm?: string
    showSourceEntity?: boolean
    onUpdate: (noteCuid: string, body: string, tags: string[]) => Promise<void>
    onDelete: (note: Note) => Promise<void>
    onTogglePin: (note: Note) => Promise<void>
  }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [editedBody, setEditedBody] = useState(note.body)
    const editInputRef = useRef<HTMLTextAreaElement>(null)
    const [sourceEntityType, sourceEntityCuid] = note.sourceEntity.split('|')
    const sourceEntityTypeDisplay = sourceEntityType.split('|')[0].replace(/\b\w/g, (l) => l.toUpperCase())

    let sourceEntityUri
    switch (sourceEntityType) {
      case 'tenant':
        sourceEntityUri = `/tenant/tenants/${sourceEntityCuid}`
        break
      case 'program':
        sourceEntityUri = `/program/programs/${sourceEntityCuid}`
        break
      case 'property':
        sourceEntityUri = `/tenant/tenants?propertyId=${sourceEntityCuid}`
        break
    }

    useEffect(() => {
      if (isEditing && editInputRef.current) {
        editInputRef.current.focus()
        editInputRef.current.setSelectionRange(editedBody.length, editedBody.length)
        // editInputRef.current.style.height = 'auto'
        editInputRef.current.style.height = editInputRef.current.scrollHeight + 5 + 'px'
      }
    }, [isEditing])

    const handleUpdate = async () => {
      if (editedBody.trim() !== note.body) {
        await onUpdate(note.cuid, editedBody.trim(), note.tags)
      }
      setIsEditing(false)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        handleUpdate()
      } else if (e.key === 'Escape') {
        e.stopPropagation()
        setIsEditing(false)
        setEditedBody(note.body.trim())
      }
    }

    return (
      <div
        className={classnames(
          'mt-4 flex flex-row group p-2 rounded-lg border',
          note.isPinned
            ? 'bg-indigo-100 dark:bg-indigo-500/15 border-indigo-500/20 dark:border-indigo-400/20'
            : 'bg-white dark:bg-slate-100/5 border-slate-200 dark:border-slate-700'
        )}
        role="listitem"
      >
        <div className="flex flex-col items-center gap-1">
          <div className="mt-1 mr-2 min-h-8 min-w-8 h-8 w-8 flex flex-col items-center justify-center rounded-full border border-slate-200 dark:border-slate-600 bg-slate-100 dark:bg-slate-700 p-1 text-xs text-slate-500 dark:text-slate-300">
            {(note.author.firstName && note.author.lastName
              ? note.author.firstName.slice(0, 1) + note.author.lastName.slice(0, 1)
              : note.author.email ?? 'Anonymous'
            )
              .slice(0, 2)
              .toUpperCase()}
          </div>
        </div>
        <div className="flex flex-col w-full">
          {isEditing ? (
            <div>
              <textarea
                ref={editInputRef}
                value={editedBody}
                onChange={(e) => setEditedBody(e.target.value)}
                onKeyDown={handleKeyDown}
                // onBlur={() => {
                //   setIsEditing(false)
                //   setEditedBody(note.body)
                // }}
                // onBlur={handleUpdate}
                className={classnames(getInputStyles())}
                rows={3}
                style={{ lineHeight: 1.4 }}
              />
              <div className="w-full text-xs font-normal text-slate-400 dark:text-slate-300 text-center">Enter to save, Esc to cancel</div>
            </div>
          ) : (
            <div className="flex justify-between items-start">
              <span className="text-slate-700 dark:text-slate-100">
                {note.body.split(new RegExp(`(${searchTerm})`, 'gi')).map((part, i) =>
                  part.toLowerCase() === searchTerm?.toLowerCase() ? (
                    <mark key={i} className="text-yellow-800 dark:text-yellow-200 bg-yellow-100 dark:bg-yellow-500/30">
                      {part}
                    </mark>
                  ) : (
                    part
                  )
                )}
              </span>
              {canModify && (
                <div className="relative">
                  <div className="absolute p-1 right-0 bg-slate-200 dark:bg-slate-600 rounded-md w-fit flex gap-1 opacity-0 group-hover:opacity-100 transition-opacity shadow-lg shadow-slate-400/20 dark:shadow-slate-900/30">
                    <TagChooser note={note} onUpdate={onUpdate} />
                    <button
                      onClick={() => onTogglePin(note)}
                      className="text-xs p-1 rounded-md hover:bg-indigo-200 dark:hover:bg-indigo-500/50 text-indigo-500 hover:text-indigo-400 dark:text-indigo-100 dark:hover:text-indigo-200"
                    >
                      <Tooltip
                        content={note.isPinned ? <div className="text-xs whitespace-nowrap">Unpin</div> : <div className="text-xs whitespace-nowrap">Pin</div>}
                        arrow={false}
                        className="text-xs z-10 pointer-events-none select-none bg-slate-600 dark:bg-slate-950 border dark:border-0 dark:border-0"
                      >
                        {note.isPinned ? <BookmarkSlashIcon className="size-4" /> : <BookmarkIcon className="size-4" />}
                      </Tooltip>
                    </button>
                    <button
                      onClick={() => setIsEditing(true)}
                      className="text-xs p-1 rounded-md hover:bg-indigo-200 dark:hover:bg-indigo-500/50 text-indigo-500 hover:text-indigo-400 dark:text-indigo-100 dark:hover:text-indigo-200"
                    >
                      <Tooltip
                        content={<div className="text-xs whitespace-nowrap">Edit</div>}
                        arrow={false}
                        className="text-xs z-10 pointer-events-none select-none bg-slate-600 dark:bg-slate-950 border dark:border-0 dark:border-0"
                      >
                        <PencilIcon className="size-4" />
                      </Tooltip>
                    </button>
                    <button
                      onClick={() => onDelete(note)}
                      className="text-xs p-1 rounded-md hover:bg-indigo-200 dark:hover:bg-indigo-500/50 text-indigo-500 hover:text-indigo-400 dark:text-indigo-100 dark:hover:text-indigo-200"
                    >
                      <Tooltip
                        content={<div className="text-xs whitespace-nowrap">Delete</div>}
                        arrow={false}
                        className="text-xs z-10 pointer-events-none select-none bg-slate-600 dark:bg-slate-950 border dark:border-0 dark:border-0"
                      >
                        <TrashIcon className="size-4" />
                      </Tooltip>
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="mt-2 inline text-xs font-normal text-slate-500 dark:text-slate-400">
            <span className="inline whitespace-nowrap">
              <Tooltip
                content={formatDistanceToNow(new Date(note.createdAt), { addSuffix: true }).replace(/^\w/, (c) => c.toUpperCase())}
                placement="bottom"
                arrow={false}
                className="inline text-xs z-10 pointer-events-none select-none bg-slate-600 dark:bg-slate-950 border dark:border-0 dark:border-0"
              >
                <span className="inline">{format(new Date(note.createdAt), "EEE MMM d, yyyy 'at' h:mm aa")}</span>
              </Tooltip>
            </span>
            <span className="whitespace-nowrap">
              {' by '}
              <span>
                {note.author.firstName && note.author.lastName
                  ? `${note.author.firstName} ${note.author.lastName}`
                  : note.author.email != null
                  ? note.author.email
                  : 'Anonymous'}
              </span>
            </span>
            {showSourceEntity && note.sourceEntityName && (
              <span className="inline">
                {' for '}
                <span className="inline text-xs font-normal text-slate-500 dark:text-slate-400">
                  {sourceEntityTypeDisplay}{' '}
                  <Link to={sourceEntityUri ?? ''} className="inline text-xs font-normal text-indigo-500 dark:text-indigo-300 hover:underline">
                    {note.sourceEntityName}
                  </Link>
                </span>
              </span>
            )}
          </div>
          {note.tags.length > 0 && (
            <div>
              <span className="inline-block text-xs font-normal text-slate-500 dark:text-slate-400">Tags:</span>
              {note.tags.map((tag) => (
                <span className="mt-1 ml-1 inline-flex items-center gap-x-0.5 rounded-md bg-gray-100 dark:bg-gray-800 px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300">
                  {tag}
                  <button
                    onClick={() => {
                      onUpdate(
                        note.cuid,
                        note.body,
                        note.tags.filter((t) => t !== tag)
                      )
                    }}
                    type="button"
                    className="group relative -mr-1 size-3.5 rounded-sm hover:bg-gray-500/20 dark:hover:bg-gray-400/20"
                  >
                    <span className="sr-only">Remove</span>
                    <svg
                      viewBox="0 0 14 14"
                      className="size-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75 dark:stroke-gray-400/50 dark:group-hover:stroke-gray-400/75"
                    >
                      <path d="M4 4l6 6m0-6l-6 6" />
                    </svg>
                    <span className="absolute -inset-1" />
                  </button>
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default NoteItem
