import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { getBillingOptions, getIncentivePlanOptions, getTenant } from './Tenants.slice'
import { updateTenantApi } from '../../../apis/apis'
import PanelContact from './components/PanelContact'
import TabMailingPreference from './components/TabMailingPreference'
import Panel from '../../../components/Panel'
import Badge from '../../../components/Badge'
import InlineEditInput from '../../../components/Form/InlineEditInput'
import TabPanel from './components/TabPanel'
import TabGeneral from './components/TabGeneral'
import TabServiceAgreements from './components/TabServiceAgreements'
import TabAccountManagers from './components/TabAccountManagers'
import TabPayments from './components/TabPayments'
import TabInvoices from './components/TabInvoices'
import classnames from 'classnames'
import TabBillingOptions from './components/TabBillingOptions'
import TabIncentivePlans from './components/TabIncentivePlans'
import { useAppDispatch, useAppSelector } from '../../../shared/redux/hooks'
import DisplayField from '../../../components/Form/DisplayField'
import * as Yup from 'yup'
import Notes from '../../notes/components/NotesDrawer'
declare global {
  interface Window {
    impersonation_ephemeralToken: string | undefined
    impersonation_email: string | undefined
  }
}

const Tenant: React.FC = () => {
  const [isInitializing, setInitializing] = useState(true)
  const [isHighlighting, setHighlighting] = useState(false)
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const qpTab = searchParams.get('tab') ?? undefined
  const qpServiceAccountNumber = searchParams.get('serviceAccountNumber') ?? undefined
  const { tenantId } = useParams()
  const { permissions } = useAppSelector((state) => state.app)

  const { loading, refreshingTenant, error, tenant, billingOptions, incentivePlanOptions } = useAppSelector((state) => state.tenants)

  const hasSalesforceOpsROPermission = (): boolean => {
    if (permissions == null) return false
    return permissions?.includes('sfops:view:link')
  }
  const hasSalesforceOpsRWPermission = (): boolean => {
    if (permissions == null) return false
    return permissions?.includes('sfops:edit:id') && permissions?.includes('sfops:view:link')
  }

  useEffect(() => {
    billingOptions == null && dispatch(getBillingOptions())
    incentivePlanOptions == null && dispatch(getIncentivePlanOptions())
    dispatch(getTenant(tenantId as string)).then(() => {
      setInitializing(false)
    })
  }, [dispatch, tenantId])

  useEffect(() => {
    const element = document.getElementById(qpServiceAccountNumber as string)
    const container = document.getElementsByClassName('mainCtr')[0]
    const timeout = setTimeout(() => {
      container?.scrollTo({
        top: element != null ? element.offsetTop - 50 : 0,
      })
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [loading, qpServiceAccountNumber, tenant?.serviceAccounts])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHighlighting(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [loading, tenant?.serviceAccounts])

  if ((loading && !refreshingTenant) || isInitializing) {
    return <div className="p-6 text-sm dark:text-white">Loading...</div>
  }

  if (!loading && error) {
    return <div className="p-6 text-sm dark:text-white">Tenant not found</div>
  }

  return (
    <>
      <Notes sourceEntity={`tenant|${tenant?.id}`} label={tenant?.name} />
      <div className="py-6 flex-1 relative focus:outline-none text-sm dark:text-white">
        <div id="header" className={classnames('mx-auto pt-0.5 pb-2 sm:px-6 md:px-8 sticky -top-px bg-slate-100 dark:bg-slate-900 z-10 mr-16')}>
          <InlineEditInput
            name="name"
            label="Tenant name"
            hideLabel
            required
            initialValue={tenant?.name}
            handleSubmit={async (editedValue) => {
              await updateTenantApi(tenantId as string, { name: editedValue })
              await dispatch(getTenant(tenantId as string))
            }}
            className="text-2xl font-medium dark:text-white"
          />
          {/* Display a panel with the property name and Salesforce account id if the user has the SALESFORCE_OPS role.
              otherwise, just display the property name under the tenant name. */}
          {hasSalesforceOpsROPermission() === false && (
            <div className="text-lg dark:text-white">
              <Link to={`/tenant/tenants?propertyId=${tenant?.property.id}`}>
                {tenant?.property.name} - {tenant?.property.location}
              </Link>
            </div>
          )}
        </div>
        <div className="mx-auto p-4 sm:px-6 md:px-8">
          <div className="grid grid-cols-2 gap-6">
            <Panel header="Summary" className="my">
              <div className={classnames('grid grid-auto-flow gap-4 m-2', 'grid-cols-2')}>
                <DisplayField label="Property">
                  <Link to={`/tenant/tenants?propertyId=${tenant?.property.id}`} className="underline">
                    {tenant?.property.name}
                  </Link>
                </DisplayField>
                {/* 
                    If the user has Salesforce OPS RW permissions, display an editable link field, if they just have Salesforce OPS RO 
                    permissions, just display a link field, otherwise, display "Not set" 
                  */}
                {hasSalesforceOpsRWPermission() === true ? (
                  <InlineEditInput
                    name="salesforceAccountId"
                    label="Salesforce Account ID"
                    required
                    initialValue={tenant?.salesforceAccountId === 'N/A' ? '' : tenant?.salesforceAccountId}
                    handleSubmit={async (editedValue) => {
                      await updateTenantApi(tenantId as string, { salesforceAccountId: editedValue }).then(() => {
                        dispatch(getTenant(tenantId as string))
                      })
                    }}
                    className="font-medium"
                    linkTemplate="https://kingenergy.lightning.force.com/lightning/r/Account/@@value@@/view"
                    validationSchema={Yup.string().matches(/^(?:[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18})$/, 'Must be 15 or 18 characters')}
                  />
                ) : tenant?.salesforceAccountId != null && tenant?.salesforceAccountId !== 'N/A' ? (
                  <DisplayField label="Salesforce Account ID">
                    <Link
                      target="_blank"
                      className="underline"
                      to={`https://kingenergy.lightning.force.com/lightning/r/Account/${tenant?.salesforceAccountId}/view`}
                    >
                      {tenant?.salesforceAccountId}
                    </Link>
                  </DisplayField>
                ) : (
                  <DisplayField label="Salesforce Account ID">Not set</DisplayField>
                )}
              </div>
            </Panel>
            <Panel header="Primary Contact">
              <PanelContact contact={tenant?.contacts?.[0]} />
            </Panel>
          </div>

          <div className="mt-8 mb-5 text-lg font-normal dark:text-white">Tenant Accounts</div>

          {tenant?.serviceAccounts?.length === 0 && <div className="dark:text-white">No tenant accounts found.</div>}

          {tenant?.serviceAccounts?.map((serviceAccount, index) => {
            const {
              billType: kingBillType,
              includeChart,
              includeSummary,
              energyDiscount,
              demandDiscount,
              billingMode,
            } = serviceAccount.billingConfiguration.tenantAccountConfiguration ?? {}

            const { billType: utilityBillType, ccaProgram, demandCreditProgram } = serviceAccount.billingConfiguration.utilityAccountConfiguration ?? {}
            return (
              <Panel
                id={serviceAccount.kingAccountNumber}
                header={
                  <div className="flex justify-between">
                    <div className="flex flex-col justify-center">
                      <div>{serviceAccount.kingAccountName}</div>
                      <div className="text-xs font-normal">{serviceAccount.kingAccountNumber}</div>
                    </div>
                    <div className="grid grid-cols-3 gap-3 text-center">
                      <div className="-mt-1">
                        <div className="text-xs font-normal mb-0.5 text-text-king-blue dark:text-slate-200">Account</div>
                        <Badge
                          status={
                            serviceAccount.invoices.results.length > 0 && serviceAccount.invoices.results[0].paymentStatus === 'UNPAID' ? 'UNPAID' : 'CURRENT'
                          }
                          className="justify-end"
                        />
                      </div>
                      <div className="-mt-1">
                        <div className="text-xs font-normal mb-0.5 text-king-blue dark:text-slate-200">Paperless</div>
                        <Badge status={serviceAccount.mailingPreference.paperless ? 'ENABLED' : 'DISABLED'} className="justify-end" />
                      </div>
                      <div className="-mt-1">
                        <div className="text-xs font-normal mb-0.5 text-king-blue dark:text-slate-200">AutoPay</div>
                        <Badge status={serviceAccount.paymentPreference.autopay.enabled ? 'ENABLED' : 'DISABLED'} className="justify-end" />
                      </div>
                    </div>
                  </div>
                }
                className="mb-6"
                headerClassName={classnames({
                  'bg-orange-50': isHighlighting && serviceAccount.kingAccountNumber === qpServiceAccountNumber,
                })}
                key={index}
              >
                <TabPanel selectedKey={serviceAccount.kingAccountNumber === qpServiceAccountNumber && isHighlighting ? +(qpTab ?? 0) : undefined}>
                  <TabGeneral title="General" data={serviceAccount} />
                  <TabMailingPreference title="Mailing preference" data={serviceAccount} />
                  <TabServiceAgreements
                    title="Service agreements"
                    data={serviceAccount.serviceAgreements.map((sa) => ({ ...sa, tenantAccountNumber: serviceAccount.kingAccountNumber }))}
                    hasSalesforceOpsROPermission={hasSalesforceOpsROPermission}
                    hasSalesforceOpsRWPermission={hasSalesforceOpsRWPermission}
                  />
                  <TabAccountManagers title="Account managers" tenantAccountCuid={serviceAccount.id} data={serviceAccount.accountManagers} />
                  <TabPayments title="Payments" serviceAccount={serviceAccount} />
                  <TabInvoices title="Invoices" data={serviceAccount.invoices} />
                  <TabBillingOptions
                    title="Billing options"
                    kingFormInitialValues={{
                      billType: kingBillType,
                      billingMode,
                      includeChart,
                      includeSummary,
                      energyDiscount,
                      demandDiscount,
                    }}
                    utilityFormInitialValues={{
                      billType: utilityBillType,
                      ccaProgram,
                      demandCreditProgram,
                    }}
                    utilityBillingOptions={billingOptions?.utilityBillTypes ?? [{ STANDARD: 'Standard' }]}
                    kingBillingOptions={billingOptions?.kingBillTypes ?? [{ STANDARD: 'Standard' }]}
                    enrollmentOptions={billingOptions?.enrollmentStatuses ?? [{ ENROLLED: 'Enrolled' }, { NOT_ENROLLED: 'Not enrolled' }]}
                    billingModeOptions={billingOptions?.billingMode ?? [{ STANDARD: 'Standard' }]}
                    tenantAccountId={serviceAccount.id}
                  />
                  <TabIncentivePlans
                    title="Incentive plans"
                    data={serviceAccount.incentivePlans}
                    tenantAccountId={serviceAccount.id}
                    incentivePlanOptions={incentivePlanOptions}
                    latestKingInvoiceDueDate={serviceAccount.currentInvoice?.dueDate}
                  />
                </TabPanel>
              </Panel>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Tenant;
