import React from 'react'
import { Tooltip } from 'flowbite-react'

interface TagBadgeProps {
  name: string
  onClose: () => Promise<void>
  tagDescription?: string
}

const TagBadge: React.FC<TagBadgeProps> = ({ name, onClose, tagDescription }) => {
  return (
    <Tooltip
      content={tagDescription}
      placement="bottom"
      arrow={false}
      className="text-xs z-10 pointer-events-none select-none bg-slate-600 dark:bg-slate-950 border dark:border-0 dark:border-0"
    >
      <span className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 dark:bg-gray-800 px-2 py-1 mr-2 font-medium text-gray-600 dark:text-gray-300 ring-1 ring-inset ring-gray-500/10 dark:ring-gray-400/20">
        {name}
        <button
          type="button"
          className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20 dark:hover:bg-gray-400/20"
          onClick={() => {
            onClose().catch((error) => {
              console.error('Error closing tag', error)
            })
          }}
        >
          <span className="sr-only">Remove</span>
          <svg
            viewBox="0 0 14 14"
            className="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75 dark:stroke-red-400/50 dark:group-hover:stroke-red-400/75"
          >
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="absolute -inset-1" />
        </button>
      </span>
    </Tooltip>
  )
}

export default TagBadge
