import React from "react";
import classnames from "classnames";

export interface PropTypes {
  size?: 1 | 2 | 3 | 4 | 5
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  color?: 'indigo' | 'emerald' | 'red' | 'slate' | 'orange'
  onClick?: any
  tabIndex?: number
  children?: React.ReactNode
  isSaving?: boolean
}

export const Button: React.FC<PropTypes> = (props) => {
  const { size = 3, className, disabled = false, type = 'button', color = 'indigo', children, isSaving = false, ...rest } = props

  return (
    <button
      type={type}
      disabled={disabled || isSaving}
      className={classnames(
        'border-transparent font-medium rounded-md',
        {
          // Indigo
          'text-indigo-600 dark:text-indigo-50 bg-indigo-100 dark:bg-indigo-900 hover:bg-indigo-200 dark:hover:bg-indigo-800 focus:ring-indigo-500':
            color === 'indigo',
          // Emerald
          'text-emerald-600 dark:text-emerald-50 bg-emerald-100 dark:bg-emerald-900 hover:bg-emerald-200 dark:hover:bg-emerald-800 focus:ring-emerald-500':
            color === 'emerald',
          // Red
          'text-red-600 dark:text-red-50 bg-red-100 dark:bg-red-900 hover:bg-red-200 dark:hover:bg-red-800 focus:ring-red-500': color === 'red',
          // Slate
          'text-slate-600 dark:text-slate-50 bg-slate-100 dark:bg-slate-900 hover:bg-slate-200 dark:hover:bg-slate-800 focus:ring-slate-500': color === 'slate',
          // Orange
          'text-orange-600 dark:text-orange-50 bg-orange-100 dark:bg-orange-900 hover:bg-orange-200 dark:hover:bg-orange-800 focus:ring-orange-500':
            color === 'orange',
        },
        'focus:outline-none focus:ring-2 focus:ring-offset-2',
        '[&>a]:text-inherit',
        {
          'px-2.5 py-1.5 text-xs': size === 1,
          'px-3 py-2 text-sm': size === 2 || size === 3,
          'px-4 py-2 text-base': size === 4,
          'px-6 py-3 text-base': size === 5,
          'opacity-50 cursor-default pointer-events-none': disabled,
        },
        className
      )}
      {...rest}
    >
      {isSaving ? (
        <svg className="animate-spin mr-2 h-5 w-5 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : null}
      {children}
    </button>
  )
}

export default Button;
