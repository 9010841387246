import React, { Fragment, useState, useRef, useEffect } from "react";
import { Combobox, Transition, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import classnames from 'classnames'
import { getInputStyles } from './styles'

interface ComboboxItem {
  id: string | number | null
  name: string
  description?: string
}

interface PropTypes {
  className?: string
  data: Array<ComboboxItem>
  value: any
  handleChange: (value: any) => void
  label?: string | React.ReactNode
  labelPlural?: string
  wildCardDisabled?: boolean
  autoFocus?: boolean
  placeholder?: string
  isNotFilter?: boolean
  disabled?: boolean
  description?: string
}

export const ComboboxAutocomplete: React.FC<PropTypes> = (props) => {
  const {
    data,
    value,
    label,
    handleChange,
    labelPlural = 'items',
    wildCardDisabled = false,
    autoFocus,
    placeholder,
    isNotFilter = false,
    disabled,
    className,
    description,
  } = props
  const inputEl = useRef<HTMLInputElement>(null)

  // Allow the input to be focused on mount if autoFocus is true
  useEffect(() => {
    if ((autoFocus ?? false) && inputEl.current != null) {
      inputEl.current.focus()
    }
  }, [autoFocus])

  const wildcardItem: ComboboxItem = {
    id: null,
    name: `All ${labelPlural.toLowerCase()}`,
    description: `Select all ${labelPlural.toLowerCase()}`,
  }
  // Check to see if the wildcard is disabled, if so, don't add it to the list
  const dataWithWildcardItem = !wildCardDisabled ? [wildcardItem, ...data] : data

  const [query, setQuery] = useState<string | null>(null)

  // If the wildcard is disabled, set the selected item to the first item in the list or an empty string
  // If the wildcard is enabled, set the selected item to the wildcard item or an empty string
  const selected = wildCardDisabled
    ? dataWithWildcardItem.filter((item) => item.id === value)[0] ?? ''
    : dataWithWildcardItem.filter((item) => item.id === value)[0] ?? wildcardItem
  const filteredItems =
    query === ''
      ? dataWithWildcardItem
      : dataWithWildcardItem.filter((item) =>
          item.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query != null ? query.toLowerCase().replace(/\s+/g, '') : '')
        )

  return (
    <div className={classnames('w-full', className)}>
      <label className="text-sm font-medium text-slate-700 dark:text-slate-300 truncate text-left">
        <div className="flex flex-col">
          <div>{label}</div>
          {description != null && <span className="text-xs text-slate-500 dark:text-slate-400">{description}</span>}
        </div>
      </label>
      <Combobox
        immediate
        value={selected}
        onChange={(option) => {
          setQuery(null)
          return handleChange(option?.id ?? undefined)
        }}
        disabled={disabled}
        onClose={() => setQuery(null)}
      >
        <div className={classnames('relative')}>
          <ComboboxInput
            value={isNotFilter ? (query === null ? selected.name : '') : query ?? selected.name}
            onChange={(event) => setQuery(event.target.value)}
            className={classnames(getInputStyles())}
            displayValue={(item: any) => item?.name}
            placeholder={placeholder ?? 'Search for a(n) ' + label + '...'}
          />
          <ComboboxButton
            className={classnames(
              'absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none border-0',
              disabled === true ? 'cursor-not-allowed' : ''
            )}
          >
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>

          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ComboboxOptions className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-900 py-1 pl-0 text-base shadow-lg ring-1 ring-black dark:ring-gray-700 ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredItems.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700 dark:text-gray-300">{`No ${labelPlural.toLowerCase()} found`}</div>
              ) : (
                filteredItems.map((item) => (
                  <ComboboxOption
                    key={item.id}
                    value={item}
                    className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 dark:text-gray-100 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none data-[focus]:font-semibold"
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{item.name}</span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-500 dark:text-indigo-500">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                        {item.description != null && item.description !== '' && (
                          <span className={`text-xs ${selected ? 'font-medium' : 'font-normal'} overflow-auto w-60 whitespace-normal break-words`}>
                            {item.description}
                          </span>
                        )}
                      </>
                    )}
                  </ComboboxOption>
                ))
              )}
            </ComboboxOptions>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export default ComboboxAutocomplete;
