import React, { ChangeEvent, useState, useEffect } from "react";
import { useDebounce } from "usehooks-ts";
import Input from '../Form/Input'

export interface QuickSearchProps {
  title: string
  initialValue?: string
  placeholder?: string
  handleChange: (value: string) => void
}

const QuickSearch: React.FC<QuickSearchProps> = (props) => {
  const { title, initialValue, placeholder } = props
  const [value, setValue] = useState<string>(initialValue ?? '')
  const debouncedValue = useDebounce<string>(value, 500)

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value)
  }

  const handleValueChange = props.handleChange

  useEffect(() => {
    handleValueChange(debouncedValue ?? '')
  }, [debouncedValue, handleValueChange])

  return (
    <div className="">
      <label htmlFor="search" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
        {title}
      </label>
      <Input type="text" name="search" id="search" value={value} onChange={(e) => handleChange(e)} placeholder={placeholder} />
    </div>
  )
}

export default QuickSearch;
